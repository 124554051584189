/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);


        // Scroll Up
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });


        $('.testimonial-slider').slick({
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: true,
          responsive: [
            {
              breakpoint: 1366,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
                dots: true
              }
            }
          ]
        });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Gallery page
    'page': {
      init: function() {

        $('.photo-gallery').lightGallery({
            thumbnail:true,
            selector: '.gallery-item'

        });

        $('#launchGallery').click(function(){
            $('.gallery-item').trigger('click');
        });

      }
    },
    // Contact page
    'contact': {
      init: function() {

        document.initMap = function() {
            var geocoder;
            var map;

            function initialize_gmap() {

              geocoder = new google.maps.Geocoder();
              var latlng = new google.maps.LatLng(-45.2538, 69.4455);

              var myOptions = {
                zoom: 15,
                center: latlng,
                scrollwheel: false,
                mapTypeControl: false,
                navigationControl: false,
                navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
                mapTypeId: google.maps.MapTypeId.ROADMAP
              };

              map = new google.maps.Map(document.getElementById("map_canvas"), myOptions);

            }

            function geocode(address, title) {

              if (geocoder) {

                geocoder.geocode({
                  'address': address
                }, function(results, status) {

                  if (status === google.maps.GeocoderStatus.OK) {

                    if (status !== google.maps.GeocoderStatus.ZERO_RESULTS) {
                      var bound = new google.maps.LatLngBounds();
                      bound.extend(results[0].geometry.location);

                      map.setCenter(bound.getCenter());


                      var infowindow = new google.maps.InfoWindow({
                        content: '<h2>' + title + '<br>' +
                        '<small><a href="https://www.google.com/maps/dir/?api=1&destination=' + results[0].geometry.location + '" target="_blank">Directions</a></small></h2>' +
                        '<strong>' + address + '</strong>',
                        size: new google.maps.Size(150, 50)
                      });

                      var marker = new google.maps.Marker({
                        position: results[0].geometry.location,
                        map: map,
                        title: address
                      });

                      google.maps.event.addListener(marker, 'click', function() {
                        infowindow.open(map, marker);
                      });

                    } else {
                      alert("No results found");
                    }

                  } else {
                    $('#map_canvas').remove();
                  }

                });
              }

              }

              var locations = [];
              $('.location-map').each(function() {
                var location = [];
                location.push($(this).find('.map-data').find('.address').data('address'));
                location.push($(this).find('.map-data').find('.title').data('title'));
                locations.push(location);
              });


              if($('#map_canvas').length) {
                initialize_gmap();
                for (i = 0; i < locations.length; i++) {
                  geocode(locations[i][0], locations[i][1]);
                }
              }
        };

        var maps_api_key = 'AIzaSyCbWGO_wnZXRRP1HCDbCt5OSwPJs0nS0XE';
        var maps_callback = 'document.initMap';
        var maps_api_url = 'https://maps.google.com/maps/api/js';
        var maps_script_tag = document.createElement('script');
        maps_script_tag.setAttribute('type', 'text/javascript');
        maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        maps_script_tag.setAttribute('defer', '');
        maps_script_tag.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
